<template>
  <BookingPosition
    displayedCard="pending"
  >
    <BookingTechnicianPendingCard
    />
  </BookingPosition>
</template>

<script>
import BookingPosition from '@/views/BookingPosition.vue'
import BookingTechnicianPendingCard from '@/components/BookingPosition/BookingTechnicianPendingCard.vue'

export default {
  name: 'BookingPositionPendingList',
  components: {
    BookingPosition,
    BookingTechnicianPendingCard
  }
}
</script>

<style lang="less">
</style>
